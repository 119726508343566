<template>
  <div class="good-item" :class="{ 'good-item-active': isActive }">
    <div class="dura_alias">{{ item.dura_alias }}</div>
    <div class="img-box flex-c">
      <img :src="item.cover" alt="" srcset="" />
    </div>
    <div class="good-title ellipsis">
      {{ item.name }}
    </div>
    <div @click="closeOnClick" v-if="showCloseBtn" class="item-close-btn"></div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    showCloseBtn: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeOnClick() {
      this.$emit("closeOnClick");
    },
  },
};
</script>

<style lang="scss">
.good-item {
  width: 100%;
  padding: 0.08rem 0.1rem;
  font-size: 0.1rem;
  text-align: center;
  background: url(../../../assets/images/new/ronglian-bg1.png) no-repeat;
  background-size: 100% 100%;
  position: relative;
  .dura_alias {
    margin-bottom: 0.04rem;
    color: #adadad;
  }
  .img-box {
    width: 100%;
    height: 0.5rem;
    margin-bottom: 0.04rem;
    img {
      height: 100%;
    }
  }
  .good-title {
  }
  .item-close-btn {
    position: absolute;
    right: 0.05rem;
    top: 0.05rem;
    width: 0.18rem;
    height: 0.14rem;
    background: url(../../../assets/images/new/ronglian-close.png) no-repeat;
    background-size: 100% 100%;
  }
}
.good-item-active {
  background: url(../../../assets/images/new/ronglian-bg2.png) no-repeat;
  background-size: 100% 100%;
}
</style>
